<template>
  <div class="aggreement">
    <h1>河小象用户服务协议4.0</h1>
    <h4 class="right">最新修改日期：2021年 <span style="color: red">8</span>月 <span style="color: red">24</span>日</h4>
    <h4 class="right">版本生效日期：2021年 <span style="color: red">8</span>月 <span style="color: red">24</span>日</h4>
    <p class="agreement-user tent">欢迎您使用河小象服务，为维护您自身权益，请您仔细阅读各条款具体的表述。</p>
    <p class="bold tent">【注意】河小象提醒您，请仔细阅读以下全部内容，特别以黑体加粗或其他合理方式提示您注意，并请您重点阅读的条款，如免责情形与责任限制条款、争议解决与管辖条款等。未成年人应在法定监护人陪同下审阅和履行，未成年人行使和履行本协议项下的权利和义务视为已获得法定监护人的认可。如果您不同意本协议的任一或全部条款内容，请不要以确认的形式（包括但不限于：点击、进入购买程序等）进行下一步操作或使用本协议项下服务。当您确认接受本协议或开始使用本协议项下河小象服务时，即表示您与河小象已达成协议关系。</p>
    <h3 class="tent">一、定义</h3>
    <p class="normal tent">1.1 平台经营者：指经营平台的法律主体，杭州河象网络科技有限公司或其关联公司，您可随时查看平台公示的证照信息等以确定彼时的实际经营主体，本协议中也称“河小象”。</p>
    <p class="normal tent">1.2 平台：指标注名称为“河小象写字”或“河小象少儿写字”的移动客户端应用软件、微信公众号，以及将来推出的新技术形式，本协议中也称“河小象”平台。</p>
    <p class="normal tent">1.3 平台服务：指经营者基于互联网，通过平台向用户提供的各项服务，包括但不限于产品体验服务、产品在线使用服务、点评服务等，本协议中也称“服务”。</p>
    <p class="normal tent">1.4 平台规则：指包括本协议及在平台已经发布及后续发布的全部规则、实施细则、推广行为规范、公告等为有效维持平台运行而制定的文件。</p>
    <p class="normal tent">1.5 平台规则变更：包括但不限于本协议条款的更改、其他平台规则的更改、发布新的平台规则等。</p>
    <p class="normal tent">1.6 河小象学员：完成注册程序，获得平台账户的即为河小象学员，本协议中也称“您”或“用户”。</p>
    <p class="normal tent">1.7 学习产品：是指河小象发布在平台内供河小象学员进行学习的相关学习视频、音频等材料，本协议中也称“产品”，河小象享有相关的著作权。</p>
    <p class="normal tent">1.8 服务期限：是指河小象学员在购买河小象学习产品时根据自身情况选择的具体服务时长，不同于学习产品中的在线视频等材料的使用期限。</p>
    <p class="normal tent">1.9 使用期限：是指河小象学员能够使用其选购的学习产品中在线视频等材料进行学习的期限<span class="bold">（特别注意：该期限的设置使得河小象学员在其选购的产品服务期限到期后，仍可使用该类材料进行自主学习，但不享受河小象提供的在线答疑、点评等其他服务）。</span></p>
    <h3 class="tent">二、协议的变更</h3>
    <p class="normal tent">2.1 考虑国家法律法规、政策变更及互联网环境的发展，本协议并不能囊括全部权利和义务，也不能保证符合未来发展的需求，<span class="bold">故河小象有权不时地制订、修改、补充本协议及/或各类平台规则，届时河小象将按照法律规定的方式通过平台向您公示，您有义务不时关注并阅读平台向您公示的最新的平台规则及网站公告。若您不同意最新的平台规则及网站公告的任一或全部条款内容，您应立即停止继续使用本协议。若您点击同意，则视为同意适用新协议；若您点击不同意，则仍适用原协议。若河小象新发布的公告内容与本协议冲突的，以本协议为准。</span></p>
    <h3 class="tent">三、您如何成为河小象学员</h3>
    <p class="bold tent">3.1 注册</p>
    <p class="normal tent">3.1.1 您同意，在使用平台服务时，您应当按照平台页面的提示准确、完整地提供您的信息（包括但不限于名称、联系电话、联系邮箱等）或授权平台使用您的第三方账号（微信、华为账户等，我们可能会不定时存在更新）进行注册，您了解并同意，<span class="bold">您有义务保证您提供信息的真实性及有效性。</span></p>
    <p class="normal tent">3.1.2 您应当及时更新您提供的信息，以保持您所提供的信息最新、真实、完整、有效。请您知悉，<span class="bold">一旦河小象发现您提供的资料错误、不实、过时或不完整的，您将需承担因您提供信息资料有误的原因造成的您自身、他人及平台的全部损失与不利后果。同时，河小象有权中止/终止向您提供部分或全部平台服务，直至您更新并完善您的信息资料至河小象可正常向您提供服务为止，如在此期间造成的相关损失由您自行承担。</span></p>
    <p class="bold tent">3.2 帐号管理及使用</p>
    <p class="normal tent">3.2.1 您充分了解并同意，您须为自己登录帐号下的行为负责，包括但不限于您所发表的任何内容以及由此产生的后果。您应对本服务提供的内容自行加以判断，并承担因使用内容而引起的风险。</p>
    <p class="bold tent">3.2.2 您应正确使用及妥善保管、维护您的河小象平台帐号及密码，如发生泄漏、遗失、被盗等行为，而该等行为并非河小象法定过错导致，损失将由您自行承担。</p>
    <p class="normal tent"><span class="bold">3.2.3 任何通过您的账户实施的操作（包括但不限于网上点击同意各类规则协议、绑定第三方账号、使用第三方服务、发布信息、披露信息、支付费用等），均视为是您的操作，由您承担全部责任。</span>您应对使用平台服务时接触到的第三方技能/服务自行加以审慎判断，并承担因使用第三方技能/服务而引起的所有风险，包括对第三方技能/服务的合法性、准确性、完整性或实用性的依赖而产生的风险。</p>
    <p class="normal tent">3.2.4 您在此授予河小象保护您帐号安全的相关权利，河小象可定期或不定期采用不同的方式对您帐号的使用安全进行检查、验证，包括但不限于主动联系您进行身份验证、短消息验证、邮箱认证等。<span class="bold">如您无法完成验证或无正当理由拒绝验证的，河小象可合理怀疑您的帐号出现异常或被盗，因而中止向该帐号提供服务或采取进一步措施。</span></p>
    <p class="normal tent">3.2.5 河小象服务，是在您遵守本协议及相关法律法规的前提下，河小象给予您一项个人的、不可转让及非排他性的许可。<span class="bold">您仅可为非商业目的使用，并仅可用作私人学习使用。未经过合法授权，不可以营利、经营等非个人使用的目的为自己或他人（申请）开通或使用（无论您是否向他人收取费用）。</span></p>
    <p class="bold tent">3.3 产品的开通</p>
    <p class="normal tent">3.3.1 河小象提供的学习产品服务采用收费与免费结合的方式。对于付费产品，您须先成为河小象普通学员之后，才能继续进行购买服务。</p>
    <p class="normal tent">3.3.2 您可通过各种已有和未来新增的支付渠道或河小象指定的其他方式成为河小象学员。当您根据页面提示确认同意并成功支付了产品服务费和/或完成了成为河小象学员的所有程序，您即可享受对应的产品服务。</p>
    <p class="normal tent">3.3.3 您可以通过登录河小象app免费查询您的账号信息详情，包括已开通的产品服务内容、产品服务期限、产品的其他内容。</p>
    <p class="bold tent">3.3.4 请您知悉，河小象提供的体验产品服务、付费产品服务以及其他不涵盖在本协议服务项目下的河小象提供的其他产品服务为不同的服务类型，您应根据实际需求选择开通相关服务。河小象课程服务可在移动端、PC端和PAD端使用，未来将推出更多的新技术形式。</p>
    <p class="bold tent">3.3.5 河小象提供的学习产品仅可在平台内进行学习，未经河小象书面同意，您不得以任何目的和/或任何形式实施包括但不限于复制、下载、上传、修改、编目排序、翻译、发行、开发、转让、销售、展示、传播上述学习产品和/或资料等的行为。禁止合成、嵌套、链接河小象提供的服务；禁止利用河小象提供的产品及其片段创作衍生品。</p>
    <h3 class="tent">四、河小象提供的服务内容</h3>
    <h4 class="bold tent">您在河小象平台内享有的服务内容由河小象根据实际提供，并有权不时进行增加、删除、修改、或调整，包括但不限于：</h4>
    <p class="bold tent">4.1 产品体验服务</p>
    <p class="normal tent">4.1.1 您有机会参与平台为河小象学员准备的免费领取产品体验的活动，按照平台的提示经过相应的程序后，您将有可能获得河小象提供的体验产品。</p>
    <p class="bold tent">4.2 产品在线使用服务</p>
    <p class="normal tent">4.2.1 河小象的学员付费后，可在河小象平台进行对应的在线视频、音频等资料的学习并享受相应的其他服务，河小象提供的在线学习服务，使得您的学习将不受地点的限制,同时为了方便您反复进行学习，河小象将对应的在线视频、音频等学习资料的使用期限延长为 3 年，使得您在选购的产品服务期限到期后，仍可自行进行学习，<span class="bold">但您应知晓此时您不再享受河小象提供的在线答疑及点评等其他服务。</span></p>
    <p class="bold tent">4.2.2 您理解并同意，为保证您学习的连续性、稳定性以及学习质量，产品一经支付，不得以任何理由更换产品。</p>
    <p class="bold tent">4.3 在线答疑、点评服务</p>
    <p class="normal tent">4.3.1 河小象的学员付费后，河小象仅在服务期限内针对河小象学员在平台内提交的学习成果，提供在线答疑及点评服务。</p>
    <p class="bold tent">4.4 学习成果分享及评价服务</p>
    <p class="normal tent">4.4.1 河小象通过设置作品表彰墙、作品广场、作业榜单等版块允许河小象学员在服务期限内对学习成果进行分享、点赞及推广。</p>
    <p class="normal tent">4.4.2 河小象有权对河小象学员上传的学习成果进行推广、建立评比体系，并对评比的结果通过相应的方式体现出来。</p>
    <p class="normal tent">4.5 <span class="bold">为了使您能更快速了解河小象提供的服务和各类活动，河小象可以通过您注册时提供的联系方式向您发送有关信息（包括但不限于广告信息、服务信息、促销优惠信息等商业性信息）而无需再次征得您的同意。</span>若您不需要此类服务，可联系河小象平台客服处理。</p>
    <h3 class="tent">五、退款制度</h3>
    <p class="bold tent">5.1您可于支付之日起30个自然日内向河小象申请电子发票。您理解并同意，所开发票抬头、发票类型、发票内容由河小象依据国家税务局及河小象所在地区税务局最新规定依法开具。</p>
    <p class="bold tent">5.2您对付费购买的服务不满意的，您自购买产品之日起15天（包含本数）内，如您向河小象申请退款的，河小象经核实后，将无条件向您退还对应产品的购买款项至您的指定账户，但您报名时领取的学习礼包，退费时若您已经打开使用的，河小象将按照原价扣除。</p>
    <p class="bold tent">5.3若您自购买之日起90天内要求终止服务的，且河小象无任何违约的情形下，因河小象已向您提供部分服务，故河小象需扣除相应的费用。退款金额=支付费用总额-已激活（解锁）产品费用-违约金-其他应扣费用。<br />
    <span class="ident">（1）支付费用总额是系河小象学员实际支付的费用，不含销售折扣与折让、各种抵用券、退差价等等实际已减免或退还给学员的费用。</span><br />
    <span class="ident">（2）已激活（解锁）产品费用，根据产品内容的不同，河小象将根据具体的产品内容进行折算扣除。</span><br />
    <span class="ident">（3）违约金是指您自购买之日起90天内单方申请退款的，河小象将根据您申请退款的时间等计算违约金数额，具体计算如下表所示：</span><br />
    <table border="1" cellspacing="0" width="80%" style="border-collapse:collapse;margin:20px 40px;">
      <tr>
        <td>X=自购买之日至申请退款之日止的期限</td>
        <td>违约金比例</td>
        <td>计算公式</td>
      </tr>
      <tr>
        <td>15天＜X≤30天</td>
        <td>10%</td>
        <td>违约金=【支付费用总额-已激活产品费用-其他应扣费用】*10%</td>
      </tr>
      <tr>
        <td>30天＜X≤60天</td>
        <td>20%</td>
        <td>违约金=【支付费用总额-已激活产品费用-其他应扣费用】*20%</td>
      </tr>
      <tr>
        <td>60天＜X≤90天</td>
        <td>30%</td>
        <td>违约金=【支付费用总额-已激活产品费用-其他应扣费用】*30%</td>
      </tr>
    </table>
    <span class="ident">（4）其他应扣费用主要包括优惠产品（包括但不限于奖品等）、学习礼包（材料），退费时，已拆开的优惠产品及学习礼包按照原价收取（未拆开的优惠产品及学习礼包在您返还河小象后，该部分可按照原价正常退款）。</span><br />
    <span class="ident">（5）为免歧义，河小象特在此向您说明，您按照前述计算公式计算的河小象应在您申请退费时扣除的包括已激活产品费用、违约金及其他应扣费用的总额最高不会超出您在购买河小象产品时支付费用的总额。</span>
    </p>

    <p class="bold tent">5.4 如您自购买之日起超过90天，受限于河小象向您开通课程的限制，您将无权终止服务。请您知悉，河小象将不支持退款。</p>
    <p class="bold tent">5.5 如河小象已为您出具纸质发票，申请退费时您需退回完整的发票，无法退回完整发票的，您应承担河小象相应的税费损失；如河小象已为您出具电子发票，申请退费时请您提供原电子发票文件，同时出具不再将此发票用于公司报销和个人所得税抵扣使用的声明，无法提供原电子发票文件或者声明的，您应自行承担相应的税务稽查后果及河小象相应的税费损失。</p>
    <p class="bold tent">5.6 请您知悉，您购买了河小象产品后，因在规定的时间内完成学习，若您未在规定的时间内完成学习，视为到期自动放弃相应的服务，对应的费用河小象将不予退还。</p>
    <p class="bold tent">5.7 请您知悉，河小象学员众多，如您申请退款，满足退款条件的，河小象将积极为您处理退款事宜，但退款周期为您符合退费条件并提出退款申请后的30个工作日。</p>
    <p class="bold tent">5.8 鉴于河小象退款制度的变更，如您在本协议生效之日前购买河小象产品的，若您点击同意本协议的，则视为同意适用新协议的退款制度；若您点击不同意，则河小象将按原有的退款制度处理您的退款申请，具体您可点击以下链接查看全文：【 <a href='https://sc2.hexiaoxiang.com/app/hxx_protocol/HXXUserProtocol-Write-bak-20210824.html' target="_blank">链接</a>  】。</p>
    <h3 class="tent">六、用户内容的上传与分享相关规定</h3>
    <p class="bold tent">6.1 河小象学员充分了解并同意，河小象专门开辟相应的版块可供学员上传学习成果及其他内容，该版块仅用于河小象学员上传、分享、传送及获取信息，河小象学员需对上传的内容负责，河小象不承担任何责任。同时，河小象学员应对该平台的其他用户提供的内容自行加以判断，并承担因使用该内容而引起的所有风险。河小象有权自行判断是否同意河小象学员发布和分享信息的行为，包括在不通知用户的情况下，进行删除、屏蔽或断开链接等处理，由此产生的损失由河小象学员自行承担。河小象学员知悉并同意，河小象对其发布、分享的信息无储存义务，河小象学员应自行备份。</p>
    <p class="bold tent">6.2 除非有相反证明，河小象学员使用河小象的平台服务上传、发布或传输内容即视为河小象学员是在河小象的平台上传、发布或传输的内容的著作权人或合法授权人。除双方另有约定外，河小象学员使用河小象的平台将图片、文字、音频、视频等信息、内容公开发布、传播、分享的行为代表了河小象学员有权且同意免费授予河小象使用该信息、内容，包括但不限于通过各种方式使用该等信息、内容对平台进行宣传推广。</p>
    <h3 class="tent">七、河小象学员行为规范</h3>
    <p class="normal tent">7.1 您理解并同意，在正常使用平台服务前，您需要自行购买智能设备、手机及其他与接入互联网或移动网有关的装置并承担所需的费用（如为接入互联网而支付的上网费、为使用移动网而支付的手机费）。同时，您知晓并同意您在使用平台及相关服务时会耗用您的设备、带宽、流量等资源。</p>
    <p class="bold tent">7.2 除非法律允许或河小象书面许可，您不得对相关产品内容进行截屏、拍照、录音录像，不得私自通过任何方式传播产品内容；</p>
    <p class="bold tent">7.3 河小象学员购买的产品仅供自己使用，您的账号不得转让给其他任何第三方使用；</p>
    <p class="bold tent">7.4 河小象学员不得夸大宣传平台功能、服务，作引人误解的宣传、表述等；</p>
    <p class="bold tent">7.5 河小象学员当按时参加购买的产品学习，如果由于您自身原因错过相关产品的学习，河小象概不负责；</p>
    <p class="normal tent"><span class="bold">7.6 如果河小象学员违反前述规定的，河小象有权终止或中止为您提供服务，进行封号处理且不予退费。给河小象造成损失的，您应当予以赔偿</span>（包括但不限于直接经济损失、商誉损失及对外支付的赔偿金、和解款、律师费、诉讼费等经济损失）。</p>
    <p class="bold tent">7.7 河小象学员理解并知悉：如河小象学员在中华人民共和国大陆地区以外的国家或地区使用平台服务，受限于第三方内容版权、技能服务提供的地域限制以及跨境数据传输相关监管政策要求，您有可能不能享受正常的平台服务。</p>
    <h3 class="tent">八、个人信息保护</h3>
    <p class="normal tent"><span class="bold">8.1 河小象非常重视您个人信息的保护，在您使用河小象提供的服务时，您同意河小象按照在河小象平台上公布的隐私权政策收集、存储、使用、披露和保护您的个人信息。河小象希望通过隐私权政策向您清楚地介绍河小象对您个人信息的处理方式，因此河小象建议您完整地阅读隐私权政策，以帮助您更好地保护您的隐私权，</span>详细个人信息政策请阅《隐私权政策》。如您不同意请不要开通或停止使用本服务。</p>
    <p class="normal tent">8.2 您声明并保证，您需对您所发布的信息拥有相应、合法的权利，否则河小象有权对您发布的信息依法或依本协议进行删除或屏蔽。</p>
    <p class="normal tent">8.3 为方便您使用“河小象平台”（指“河小象”及其关联公司的提供互联网学习产品的平台）、提供的其他相关服务，若涉及第三方相应服务时，您授权 “河小象”将您在账户注册和使用产品服务过程中提供、形成的信息传递给“淘宝平台”、支付宝、微信等其他第三方相关服务提供者，或从“淘宝平台”、支付宝、微信等其他第三方相关服务提供者处获取您在注册、使用“淘宝平台”、支付宝、微信等其他第三方服务期间提供、形成的信息，<span class="bold">在获取相应的信息前河小象会提前告知您。</span></p>
    <h3 class="tent">九、 未成年人条款 </h3>
    <p class="bold tent">9.1 河小象非常注重未成年人的保护。若用户未满18周岁，则为未成年人，应在其监护人监护、指导下阅读本协议和使用本服务。</p>
    <p class="bold tent">9.2 监护人应指导子女上网应该注意的安全问题，防患于未然。若监护人同意未成年人使用河小象提供的服务，必须以监护人名义申请消费，并对未成年人使用课程服务进行正确引导、监督。未成年人行使和履行本协议项下的权利和义务即视为已获得了监护人的认可。</p>
    <h3 class="tent">十、 知识产权</h3>
    <p class="normal tent">10.1 受国际版权公约、中华人民共和国著作权法、专利法、及其他知识产权方面的法律法规的保护，<span class="bold">河小象平台的产品与/或服务及所使用的软件、界面、交互等元素、LOGO、商标、标识、图形、文字、文本、声音、音频、视频、肖像、图片、技术、材料等的所有知识产权归河小象所有和享有。</span>“知识产权”包括在专利法、版权法、商标法、反不正当竞争法中等法律规定的任何和所有权利、任何和所有其它所有权以及其中的任何和所有应用、更新、扩展和恢复。</p>
    <p class="bold tent">10.2 河小象学员不得修改、改编、翻译河小象服务所使用的软件、技术、材料等，或者创作与之相关的派生作品，不得通过反向工程、反编译、反汇编或其他类似行为获得其的源代码，否则由此引起的一切法律后果由用户负责，河小象将依法追究违约方的法律责任。</p>
    <p class="bold tent">10.3 河小象学员只能在本协议以及河小象明确授权的范围内使用河小象提供的产品与/或服务。未经河小象事先书面同意，河小象学员不得删除、掩盖或更改河小象的版权声明、商标或其它权利声明/标签。</p>
    <p class="bold tent">10.4 河小象所有和享有的知识产权，不因用户的任何使用行为而发生权利转移。</p>
    <h3 class="tent">十一、违约及处理</h3>
    <p class="normal tent">11.1 河小象学员发生如下情形之一的，视为违约：<br />
      <span class="ident">11.1.1 使用平台服务时违反有关法律法规规定的；</span><br />
      <span class="ident">11.1.2 违反本协议或平台发布的规则的。</span>
    </p>
    <p class="normal tent">11.2 如果河小象发现或收到他人举报您有违约行为的，河小象有权根据自身的判断，不经通知直接采取以下一项或多项处理措施：<br />
      <span class="ident">（1）对相关内容进行删除、屏蔽；</span><br />
      <span class="ident">（2）书面警告，以短信、邮件、站内信等一种或多种形式通知您要求您限期纠正该等行为；</span><br />
      <span class="ident">（3）限制、暂停、终止您使用部分或全部服务；</span><br />
      <span class="ident">（4）不退还任何的服务费用，且不进行任何的赔偿或补偿；</span><br />
      <span class="ident">（5）其他根据平台规则可采取的措施。</span>
    </p>
    <p class="normal tent">11.3 如若河小象就前述违规行为向您发出通知的，您有责任在河小象要求时间内进行说明并出具证据材料。<span class="bold">如若您对河小象的处理措施有异议的，应在知悉该等处理措施之日起3日内书面提出异议及证明材料，未按时提供的，视为您接受相应的处理措施。</span></p>
    <h3 class="tent">十二、免责情形与责任限制</h3>
    <p class="normal tent">12.1 河小象致力于不断提高服务的产品与技术质量、优质的用户体验以及系统的安全稳定。</p>
    <p class="normal tent">12.2 您理解并同意，河小象提供的服务可能受到多种因素的影响，河小象不保证，也不对包括但不限于下述任一情况而导致的损害承担赔偿责任：<br />
      <span class="ident bold">（1）受到计算机病毒、木马或其他恶意程序、黑客攻击的破坏；</span><br />
      <span class="ident bold">（2）可能存在的网络通讯故障、系统停机维护；</span><br />
      <span class="ident bold">（3）用户操作不当；</span><br />
      <span class="ident bold">（4）当前行业技术普遍水平所无法避免的，使用有瑕疵或未能使用平台服务；</span><br />
      <span class="ident bold">（5）其他非经营者过错、经营者无法控制或合理预见的情形。</span>
    </p>
    <p class="normal tent">12.3 除法律有明确规定或有生效裁判外，<span class="span">河小象不对任何间接性、后果性、惩罚性、偶然性、特殊性或刑罚性的损害予以赔偿。</span></p>
    <h3 class="tent">十三、服务的终止</h3>
    <p class="normal tent"><span class="bold">13.1 存在以下情况之一的，本协议约定的河小象服务将自动终止：</span><br />
    <span class="bold ident">（1）河小象在提前三十日以电话、短信、电子邮件等形式告知您后，将有权终止协议：</span><br />
    <span class="ident" style="margin-left:30px">1）因为您的违约行为，河小象主动中止或终止程服务的；</span><br />
    <span class="ident" style="margin-left:30px">2）因国家或相关政府监管部门要求或发生不可抗力事件时，河小象中止或终止服务的；</span><br />
    <span class="ident" style="margin-left:30px">3）其他根据法律法规应中止或终止服务的。</span><br />
    <span class="ident">（2）您主动中止或终止，包括但不限于您主动注销学员资格、终止学员到期未进行续费等，视为您主动终止河小象的服务；</span></p>
    <p class="normal tent">13.2 中止或终止服务后，河小象无需确保您收到特别提示或通知。当您发现无法登录或享受服务时，可以咨询河小象客服人员。</p>
    <p class="bold tent">13.3 中止或终止后的处理 <br />
      <span class="bold ident">（1）除法律规定的责任外，河小象对本服务的中止或终止对您和第三人不承担任何责任；</span><br />
      <span class="bold ident">（2）已收取的费用按照退款制度予以退还；</span><br />
      <span class="bold ident">（3）在河小象正在进行的交易，河小象将根据情况合理判断，是否继续进行或终止相关交易，并视情况决定是否进行退费或扣除相关费用作为违约金。</span>
    </p>
    <p class="normal tent">13.4 除本协议另有约定情况外，本协议自您选择接受或使用本服务后生效，直至您终止本服务/注销学员资格时终止。</p>
    <h3 class="tent">十四、通知和送达</h3>
    <p class="normal tent">14.1 对于河小象学员所有的通知均可通过河小象页公告、站内消息、客户端推送、公众号通知、学员预留的电子邮件、手机短信或常规的信件传送等方式进行，该等通知于发送之日视为已送达用户。请您应确保预留的联系方式为正确及有效，并在变更后及时登录河小象修改，如因您未提供正确的联系信息或未及时告知新的联系方式，导致无法及时收取通知，将由您自行承担由此产生的损失及法律后果。</p>
    <p class="normal tent">14.2 您理解并同意，相关行政机关、司法机关（包括但不限于法院等）有权采取本条第1款所述一种或多种方式向您送达法律文书（包括但不限于诉讼文书等），送达时间以上述送达方式中最先送达的为准。上述送达方式的适用范围包括一审、二审、再审、执行以及督促等在内的各个司法程序阶段。</p>
    <h3 class="tent">十五、 争议解决和法律适用</h3>
    <p class="normal tent">15.1 本协议条款的生效、履行、解释及争议的解决均适用中华人民共和国法律，本协议条款因与中华人民共和国现行法律相抵触而导致部分无效，不影响其他部分的效力。</p>
    <p class="bold tent">15.2 如就本协议内容或其执行发生任何争议，应尽量友好协商解决；协商不成时，任何一方均可向平台经营者所在地有管辖权的人民法院提起诉讼。</p>
    <p class="normal tent">15.3 本协议任一条款被视为废止、无效或不可执行，该条应视为可分的且并不影响本协议其余条款的有效性及可执行性。</p>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.aggreement {
  text-align: left;
  color: #444144;
  .tent {
    text-indent: 2em;
  }
  .ident {
    padding-left: 2em;
  }
  p {
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 34px;
  }
  h1 {
    font-size: 24px;
    color: #444144;
    font-weight: bold;
  }
  h3 {
    font-size: 18px;
  }
  .agreement-user {
    font-size: 18px;
    margin: 20px 0;
  }
  .bold {
    font-size: 16px;
    font-weight: bold;
  }

  h5 {
    margin-bottom: 10px;
  }
  h4 {
    margin-bottom: 10px;
  }
  .right {
    text-align: right;
  }
}
</style>
